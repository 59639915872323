import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import * as Yup from "yup"
import { useFormik } from "formik"
import Swal from "sweetalert2"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  CardSubtitle,
  FormFeedback,
  Alert,
} from "reactstrap"
import {
  addNewBillingReceipt,
  addVendorPayment,
  getBillingCompanyListInReceipt,
  getPaymentMode,
  getProjectListInBilling,
  getVendor,
  getVendorListInPayment,
  getVendorPaymentDetail,
  getVendors,
} from "store/actions"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"

import { createSelector } from "reselect"
import Proptypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { getExternalCompanyByProject, getPaymentInvoiceNumber } from "helpers/fakebackend_helper"
// import LargeModal from "./detailedSummaryModal"

const AddNewPayment = props => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const vendorPaymentState = state => state.vendors
  const BillingState = state => state.billing
  const path = useLocation()
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))

  const AllBillingProperties = createSelector(
    vendorPaymentState,
    BillingState,

    (vendor, billing) => ({
      vendorList: vendor.vendorListInPayment?.data,
      vendorPaymentDetail: vendor.vendorPaymentDetail?.data,
      paymentMode: billing.paymentMode.data,
      projectList: billing.projectList.data,
    })
  )

  const { vendorList, vendorPaymentDetail, paymentMode, projectList } =
    useSelector(AllBillingProperties)

  useEffect(() => {
    dispatch(getPaymentMode())
  }, [dispatch])

  useEffect(() => {
    if (user) {
      dispatch(getProjectListInBilling(user.user_id))
    }
  }, [user])

  useEffect(() => {
    const newPaymentData =
      vendorPaymentDetail &&
      vendorPaymentDetail.map(data => ({
        vendor_invoice_id: data.vendor_invoice_id,
        invoice_no: data.invoice_no,
        invoice_amount: data.invoice_amount,
        outstanding_amount: data.outstanding_amount,
      }))
    validation.setFieldValue("components", newPaymentData)
  }, [vendorPaymentDetail])

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      project_id: "",
      company_id: "",
      vendor_id: "",
      payment_date: new Date().toISOString().split("T")[0] || "",
      components: "",
      payment_mode: "",
      remarks: "",
      instrument_no: "",
      // tds_percentage: "",
      // totalAmountPaid: "",
      // tdsAmount: "0",
      payment_inv_no: "",
    },
    validationSchema: Yup.object().shape({
      project_id: Yup.number().required("Please select Project"),
      vendor_id: Yup.number().required("Please Select Vendor"),
      payment_mode: Yup.string().required("Please select Payment Mode"),

      payment_date: Yup.date().required("Please Enter Date"),
      components: Yup.array().of(
        Yup.object().shape({
          outstanding_amount: Yup.number().required(
            "Outstanding amount is required"
          ),
          tds_percentage: Yup.number().required("TDS Percentage is required"),
          amount_paid: Yup.number()
            .required("Payment Amount is required")
            .test("is-not-greater-than-outstanding", function (value, context) {
              const { outstanding_amount, tds_amount } = this.parent
              const parsedValue = parseFloat(value) || 0
              const parsedTdsAmount = parseFloat(tds_amount) || 0
              const parsedOutstandingAmount =
                parseFloat(outstanding_amount) || 0
              const payableValue = parsedValue + parsedTdsAmount

              if (payableValue > parsedOutstandingAmount) {
                return this.createError({
                  message: `Amount Payable cannot be greater than outstanding amount ${parsedOutstandingAmount}`,
                })
              }

              return true
            }),
            // instrument_no: Yup.string().when('payment_mode', {
            //   is: (payment_mode) => payment_mode !== 'Cash',
            //   then: Yup.string().required('Instrument No is required if payment mode is not Cash'),
            //   otherwise: Yup.string().notRequired(),
            // }),
        })
      ),
    }),

    onSubmit: async values => {
      dispatch(addVendorPayment(props.router.navigate, values))
    },
  })

  useEffect(() => {
    if (validation.values.components) {
      const totalLength = validation.values.components?.length || 0
      const totalAmountPaid = validation.values.components?.reduce(
        (sum, component) => {
          return sum + (parseFloat(component.amount_paid) || 0)
        },
        0
      )

      validation.setFieldValue("totalAmountPaid", totalAmountPaid)
    }
  }, [validation.values.components])

  useEffect(() => {
    if (validation.values.totalAmountPaid && validation.values.tds_percentage) {
      const tds =
        ((parseFloat(validation.values.tds_percentage) || 0) *
          parseFloat(validation.values.totalAmountPaid || 0)) /
          100 || 0
      validation.setFieldValue("tdsAmount", tds)
    } else {
      validation.setFieldValue("tdsAmount", 0)
    }
  }, [validation.values.totalAmountPaid, validation.values.tds_percentage])

  useEffect(() => {
    const newPaymentData =
      vendorPaymentDetail &&
      vendorPaymentDetail.map(data => ({
        invoice_id: data.vendor_invoice_id,
        invoice_date: data.invoice_date,
        payment_inv_no: data.invoice_no,
        invoice_amount: data.invoice_amount,
        outstanding_amount: data.outstanding_amount || 0,
        taxable_amount: data.taxable_amount || 0,
        gst_percentage: data.gst_percentage || 0,
        gst_amount: data.gst_amount || 0,
        amount_paid: 0,
        tds_percentage: 0,
        tds_amount: 0,
        tds_paid: data.tds_paid || 0,
      }))
    validation.setFieldValue("components", newPaymentData)
  }, [vendorPaymentDetail])

  useEffect(() => {
    if (validation.values.vendor_id) {
      dispatch(getVendorPaymentDetail(validation.values.vendor_id))
    }
  }, [validation.values.vendor_id])

  const handleInvoiceNumber = async () => {
    try {
      const value = {
        project_id: validation.values.project_id,
        invoice_date: validation.values.payment_date,
      }
      const invoiceNumber = await getPaymentInvoiceNumber(value)
      if (invoiceNumber) {
        validation.setFieldValue("payment_inv_no", invoiceNumber.payment_inv_no)
      }
    } catch (error) {
      console.error("Error fetching invoice number:", error)
    }
  }

  useEffect(() => {
    if (validation.values.project_id && validation.values.payment_date) {
      handleInvoiceNumber()
    }
  }, [validation.values.project_id, validation.values.payment_date])

  useEffect(() => {
    if (validation.values.project_id) {
      dispatch(getVendorListInPayment(validation.values.project_id))
      validation.setFieldValue("vendor_id", "")
    }
  }, [validation.values.project_id])

  const roundToTwo = num => {
    return Math.round(num * 100) / 100
  }


   const [externalCompanyList, setExternalCompanyList] = useState([])
  
    const fetchCompaniesByProject = async project => {
      try {
        const response = await getExternalCompanyByProject(project)
        setExternalCompanyList(response.data)
      } catch (error) {
        console.error("Error fetching companies:", error)
        // Optionally, handle the error (e.g., show an error message to the user)
      }
    }
  
    useEffect(() => {
      if (validation.values.project_id) {
        fetchCompaniesByProject(validation.values.project_id)
      }
    }, [validation.values.project_id])

  return (
    <React.Fragment>
      <div className="page-content ">
        <Container fluid={true}>
          <Form
            autoComplete="off"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <CardTitle className="h4 mb-3" style={{ fontSize: "20px" }}>
              <div className="d-flex gap-2 ">
                <i
                  className="mdi mdi-arrow-left font-size-20"
                  style={{ color: "grey" }}
                  onClick={() => {
                    navigate(-1)
                  }}
                ></i>
                Add Payment
              </div>
            </CardTitle>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="project_id">Project*</Label>

                          <select
                            className={`form-select ${
                              validation.touched.project_id &&
                              validation.errors.project_id
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            name="project_id"
                            value={validation.values.project_id || ""}
                          >
                            <option value="">Select Project</option>
                            {(projectList || []).map(project => (
                              <option key={project.id} value={project.id}>
                                {project.project_name}
                              </option>
                            ))}
                          </select>
                          {validation.touched.project_id &&
                            validation.errors.project_id && (
                              <div className="invalid-feedback">
                                {validation.errors.project_id}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="company_id">Company*</Label>

                          <select
                            className={`form-select ${
                              validation.touched.company_id &&
                              validation.errors.company_id
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            name="company_id"
                            value={validation.values.company_id || ""}
                          >
                            <option value="">Select Company</option>
                              {(externalCompanyList || []).map(item => (
                                <option
                                  key={item.company_id}
                                  value={item.company_id}
                                >
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          
                          {validation.touched.company_id &&
                            validation.errors.company_id && (
                              <div className="invalid-feedback">
                                {validation.errors.company_id}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="vendor_id">Vendor*</Label>

                          <select
                            className={`form-select ${
                              validation.touched.vendor_id &&
                              validation.errors.vendor_id
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            name="vendor_id"
                            value={validation.values.vendor_id}
                          >
                            <option>Select Vendor</option>
                            {(vendorList || []).map(vendor => (
                              <option key={vendor.id} value={vendor.id}>
                                {vendor.vendor_name} ( {vendor.vendor_code} )
                              </option>
                            ))}
                          </select>
                          {validation.touched.vendor_id &&
                            validation.errors.vendor_id && (
                              <div className="invalid-feedback">
                                {validation.errors.vendor_id}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="payment_date">Payment Date*</Label>
                          <Input
                            type="date"
                            id="payment_date"
                            name="payment_date"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.payment_date}
                            className={`form-control ${
                              validation.touched.payment_date &&
                              validation.errors.payment_date
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {validation.touched.payment_date &&
                          validation.errors.payment_date ? (
                            <FormFeedback type="invalid">
                              {validation.errors.payment_date}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="payment_mode">Payment Type*</Label>
                          <select
                            className={`form-select ${
                              validation.touched.payment_mode &&
                              validation.errors.payment_mode
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            name="payment_mode"
                            value={validation.values.payment_mode || ""}
                          >
                            {!validation.values.payment_mode && (
                              <option>Select Payment Mode</option>
                            )}
                            {paymentMode &&
                              paymentMode.map((mode, index) => (
                                <option key={index} value={mode.payment_mode}>
                                  {mode.payment_mode}
                                </option>
                              ))}
                          </select>
                          {validation.touched.payment_mode &&
                          validation.errors.payment_mode ? (
                            <FormFeedback type="invalid">
                              {validation.errors.payment_mode}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="payment_inv_no">Payment Number</Label>

                          <Input
                            type="text"
                            name="payment_inv_no"
                            className="form-control"
                            disabled
                            id="payment_inv_no"
                            placeholder="Payment Number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.payment_inv_no || ""}
                            invalid={
                              validation.touched.payment_inv_no &&
                              validation.errors.payment_inv_no
                                ? true
                                : false
                            }
                          />
                          {validation.touched.payment_inv_no &&
                          validation.errors.payment_inv_no ? (
                            <FormFeedback type="invalid">
                              {validation.errors.payment_inv_no}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="remarks">Remarks</Label>

                          <Input
                            type="text"
                            name="remarks"
                            className="form-control"
                            id="remarks"
                            placeholder="Enter Remarks"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.remarks || ""}
                            invalid={
                              validation.touched.remarks &&
                              validation.errors.remarks
                                ? true
                                : false
                            }
                          />
                          {validation.touched.remarks &&
                          validation.errors.remarks ? (
                            <FormFeedback type="invalid">
                              {validation.errors.remarks}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    {validation.values.payment_mode !== "Cash" && (
                      <Row>
                        <Col lg={4}>
                          <div className=" mb-2">
                            <Label htmlFor="instrument_no" className="mt-2">
                              Ins No.
                            </Label>

                            <input
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              placeholder="Enter Ins No"
                              className={`form-control  ${
                                validation.touched.instrument_no &&
                                validation.errors.instrument_no
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="instrument_no"
                              value={validation.values.instrument_no || ""}
                            />
                            {validation.touched.instrument_no &&
                            validation.errors.instrument_no ? (
                              <FormFeedback type="invalid">
                                {validation.errors.instrument_no}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    {validation?.values?.components?.length === 0 ||
                    validation?.values?.vendor_id === "" ||
                    validation?.values?.project_id === "" ? (
                      <Alert className="text-center">No Record found</Alert>
                    ) : (
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Invoice No</th>
                            <th >Invoice Date</th>
                            <th>Taxable Amount</th>
                            <th>GST Amount</th>
                            <th>Invoice Amount</th>

                            <th>Oustanding Amount</th>

                            <th>Payment</th>
                            <th>TDS Rate(%)</th>
                            <th>TDS Amount</th>
                            {/* <th>Total Amount</th> */}
                            <th>Amount Payable</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(validation?.values?.components || []).map(
                            (payment, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{payment.payment_inv_no}</td>
                                <td style={{whiteSpace: 'nowrap'}}>{payment.invoice_date}</td>
                                <td>
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      id={`invoice_amount_${index}`}
                                      name={`components.${index}.taxable_amount`}
                                      disabled
                                      value={
                                        validation.values.components[index]
                                          ?.taxable_amount || ""
                                      }
                                      className={`form-control ${
                                        validation.touched.components?.[index]
                                          ?.taxable_amount &&
                                        validation.errors.components?.[index]
                                          ?.taxable_amount
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      id={`invoice_amount_${index}`}
                                      name={`components.${index}.gst_amount`}
                                      disabled
                                      value={
                                        validation.values.components[index]
                                          ?.gst_amount || ""
                                      }
                                      className={`form-control ${
                                        validation.touched.components?.[index]
                                          ?.gst_amount &&
                                        validation.errors.components?.[index]
                                          ?.gst_amount
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      id={`invoice_amount_${index}`}
                                      name={`components.${index}.invoice_amount`}
                                      disabled
                                      value={
                                        validation.values.components[index]
                                          ?.invoice_amount || ""
                                      }
                                      className={`form-control ${
                                        validation.touched.components?.[index]
                                          ?.invoice_amount &&
                                        validation.errors.components?.[index]
                                          ?.invoice_amount
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      id={`outstanding_amount_${index}`}
                                      name={`components.${index}.outstanding_amount`}
                                      disabled
                                      value={
                                       
                                          validation.values.components?.[index]
                                            ?.outstanding_amount || 0
                                      
                                      }
                                      className={`form-control ${
                                        validation.touched.components?.[index]
                                          ?.outstanding_amount &&
                                        validation.errors.components?.[index]
                                          ?.outstanding_amount
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      id={`amount_${index}`}
                                      name={`components.${index}.amount_paid`}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      placeholder="0"
                                      value={
                                        validation.values.components[index]
                                          ?.amount_paid || ""
                                      }
                                      className={`form-control ${
                                        validation.touched.components?.[index]
                                          ?.amount_paid &&
                                        validation.errors.components?.[index]
                                          ?.amount_paid
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                    />
                                    {validation.touched.components?.[index]
                                      ?.amount_paid &&
                                    validation.errors.components?.[index]
                                      ?.amount_paid ? (
                                      <FormFeedback type="invalid">
                                        {
                                          validation.errors.components?.[index]
                                            .amount_paid
                                        }
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </td>{" "}
                                <td>
                                  <div className="mb-2">
                                    <select
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      disabled={
                                        validation?.values?.components[index]
                                          ?.tds_paid
                                      }
                                      className={`form-select ${
                                        validation.touched.components?.[index]
                                          ?.tds_percentage &&
                                        validation.errors.components?.[index]
                                          ?.tds_percentage
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      name={`components.${index}.tds_percentage`}
                                      value={
                                        validation.values.components[index]
                                          ?.tds_percentage || "0"
                                      }
                                    >
                                      <option value="" disabled>
                                        Select TDS Percentage
                                      </option>
                                      <option value="0">0</option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="5">5</option>
                                      <option value="5">5</option>
                                      <option value="7.5">7.5</option>
                                      <option value="10">10</option>
                                      <option value="15">15</option>
                                      <option value="20">20</option>
                                      <option value="25">25</option>
                                      <option value="30">30</option>
                                      <option value="40">40</option>
                                      <option value="50">50</option>
                                    </select>
                                    {validation.touched.components?.[index]
                                      ?.tds_percentage &&
                                    validation.errors.components?.[index]
                                      ?.tds_percentage ? (
                                      <FormFeedback type="invalid">
                                        {
                                          validation.errors.components?.[index]
                                            ?.tds_percentage
                                        }
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </td>
                                <td>
                                  <div className=" mb-2">
                                    <input
                                      type="text"
                                      disabled={
                                        validation?.values?.components[index]
                                          ?.tds_paid
                                      }
                                      onChange={validation.handleChange}
                                      placeholder="0"
                                      onBlur={validation.handleBlur}
                                      className={`form-control ${
                                        validation.touched.components?.[index]
                                          ?.tds_amount &&
                                        validation.errors.components?.[index]
                                          ?.tds_amount
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      name={`components.${index}.tds_amount`}
                                      value={
                                        validation.values.components[index]
                                          ?.tds_amount || ""
                                      }
                                    />
                                  </div>
                                  {validation.touched.components?.[index]
                                    ?.tds_amount &&
                                  validation.errors.components?.[index]
                                    ?.tds_amount ? (
                                    <FormFeedback type="invalid">
                                      {
                                        validation.errors.components?.[index]
                                          ?.tds_amount
                                      }
                                    </FormFeedback>
                                  ) : null}
                                </td>
                                <td>
                                  <div className=" mb-2">
                                    <input
                                      type="text"
                                      disabled
                                      className={`form-control  ${
                                        validation.touched.components?.[index]
                                          ?.payable_amount &&
                                        validation.errors.components?.[index]
                                          ?.payable_amount
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      name={`components.${index}.payable_amount`}
                                      value={
                                        roundToTwo(parseFloat(
                                          validation.values.components?.[index]
                                            ?.amount_paid || 0
                                        ) +
                                          parseFloat(
                                            validation.values.components?.[
                                              index
                                            ]?.tds_amount || 0
                                          ) || 0)
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3 text-end">
                  <button
                    type="submit"
                    className="btn btn-primary w-md me-2"
                    style={{ width: "10%" }}
                  >
                    Submit
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(AddNewPayment)

AddNewPayment.propTypes = {
  history: Proptypes.object,
}
