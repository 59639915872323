import React, { useState, useMemo, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import { Row, Col, Label, FormFeedback } from "reactstrap"
import Swal from "sweetalert2"
import { getAllCompanies } from "store/actions" // Adjust the import based on your file structure

const CompanyLocationForm = ({ validation, prefilledData }) => {
  const dispatch = useDispatch()

  // Fetch companies from Redux store
  const allCompanies1 = useSelector(
    state => state.Reports.allCompanies.companies
  )

  // Ensure data is available before processing
  useEffect(() => {
      dispatch(getAllCompanies()) // Fetch data on component mount if not available
    
  }, [dispatch])

  const groupedLocationOptions = useMemo(() => {
    if (!Array.isArray(allCompanies1) || allCompanies1.length === 0) {
      return [];
    }
    return allCompanies1.map(company => ({
      label: company.name,
      options: company.locations?.map(loc => ({
        label: `${loc.location_title} (${company.name})`,
        value: loc.id,
        company_id: company.id,
      })) || [],
    }));
  }, [allCompanies1]);
  
  

  const [selectedLocations, setSelectedLocations] = useState([])

  const handleLocationChange = selectedOptions => {
    const selectedCompanyIds = new Set(); // To track selected company IDs
    
    // Check if any company is selected more than once
    for (const option of selectedOptions) {
      if (selectedCompanyIds.has(option.company_id)) {
        Swal.fire({
          icon: "error",
          title: "Selection Error",
          text: "You can only select one location per company!",
        });
        return; // Stop further execution if validation fails
      }
      selectedCompanyIds.add(option.company_id);
    }
  
    // If validation passes, update state & Formik values
    setSelectedLocations(selectedOptions);
    console.log("selectedOptions",selectedOptions)
    const locationIds = selectedOptions.map(option => option.value);  // Location IDs
    const companyIds = [...selectedCompanyIds];  // Company IDs
    
    // Format values according to the required structure
    const companiesData = selectedOptions.map(option => ({
      company_id: option.company_id,
      companies_for_internal_external: 1, // Assuming it's the same
    }));
  
    const companyLocationsData = selectedOptions.map(option => ({
      company_id: option.company_id,
      location_for_internal_external: 1, // Assuming it's the same
      company_location_id: option.value,
    }));
  
    validation.setFieldValue("companies", companiesData);
    validation.setFieldValue("company_locations", companyLocationsData);
    validation.setFieldValue("location_id", locationIds);
    validation.setFieldValue("company_id", companyIds);
  };
  
  useEffect(() => {
    if (
      prefilledData?.companyLocations &&
      groupedLocationOptions.length > 0
    ) {
      const preSelected = prefilledData.companyLocations
        .map(location =>
          groupedLocationOptions
            .flatMap(group => group.options)
            .find(option => Number(option.value) === Number(location.company_location_id))
        )
        .filter(Boolean) // Remove null values
        console.log("preSelected",preSelected)
      if (preSelected.length > 0) {
        setSelectedLocations(preSelected);
      }
    }
  }, [prefilledData, groupedLocationOptions]);
  
  

  useEffect(() => {
    if (prefilledData?.companyLocations) {
      const companyLocationsData = prefilledData.companyLocations.map(loc => ({
        company_id: loc.company_id,
        location_for_internal_external: 1,
        company_location_id: loc.company_location_id,
      }));
  
      const companiesData = prefilledData.companyLocations.map(loc => ({
        company_id: loc.company_id,
        companies_for_internal_external: 1,
      }));
  
      console.log("companyLocationsData",companyLocationsData)
      validation.setFieldValue("company_locations", companyLocationsData);
      validation.setFieldValue("companies", companiesData);
      validation.setFieldValue(
        "location_id",
        prefilledData.companyLocations.map(loc => loc.company_location_id)
      );
      validation.setFieldValue(
        "company_id",
        prefilledData.companyLocations.map(loc => loc.company_id)
      );
    }
  }, [prefilledData]);
  

  console.log("Prefilled Data:", prefilledData);
  console.log("Grouped Options:", groupedLocationOptions);
  console.log("Selected Locations:", selectedLocations); 
  console.log("values:", validation.values); 
   return (
    <>
      {/* Multi-Select Location Dropdown */}
      <Col xxl={6} lg={6}>
        <div className="mb-3">
          <Label>Select V1malls Owned Company Locations</Label>
          <Select
            placeholder="Select Company Locations"
            options={groupedLocationOptions}
            value={selectedLocations}
            onChange={handleLocationChange}
            isMulti // Enables multiple selection
            closeMenuOnSelect={false} // Keeps dropdown open after selection
            className={
              validation.touched.location_id && validation.errors.location_id
                ? "is-invalid"
                : ""
            }
            styles={{
              groupHeading: (base) => ({
                ...base,
                color: "#000", // Black text color
                fontSize: "16px", // Increase font size
                fontWeight: "bold", // Make it bold
                padding: "10px 12px", // Add some padding
                backgroundColor: "#f8f9fa", // Light gray background (optional)
              }),
              option: (base, { data, isDisabled, isFocused, isSelected }) => ({
                ...base,
                paddingLeft: "16px", // Indent options under group labels
              }),
              multiValue: (base) => ({
                ...base,
                backgroundColor: "#bfbdbd", // Dark background color for selected values
                color: "#fff", // White text color
              }),
              multiValueLabel: (base) => ({
                ...base,
                color: "#343a40", // White text color for selected value label
              }),
              multiValueRemove: (base) => ({
                ...base,
                color: "#fff", // White color for remove icon (X)
                ':hover': {
                  backgroundColor: '#343a40', // Darken background on hover
                  color: '#fff', // Keep text color white on hover
                },
              }),
            }}
          />
          {validation.touched.location_id && validation.errors.location_id && (
            <FormFeedback type="invalid">
              {validation.errors.location_id}
            </FormFeedback>
          )}
        </div>
      </Col>
    </>
  )
}

export default CompanyLocationForm
