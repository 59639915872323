import React, { useState, useMemo, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import { Col, Label, FormFeedback } from "reactstrap"
import Swal from "sweetalert2"
import { getAllCompanies } from "store/actions" // Adjust import as needed

const CompanyLocationFormForOperator = ({ validation, prefilledData }) => {
  const dispatch = useDispatch()

  // Fetch companies from Redux store
  const allCompanies1 = useSelector(
    state => state.Reports.allCompanies.companies
  )

  // Fetch data on component mount
  useEffect(() => {
    dispatch(getAllCompanies())
  }, [dispatch])

  // Transform the company data into dropdown format
  const groupedLocationOptions = useMemo(() => {
    if (!Array.isArray(allCompanies1) || allCompanies1.length === 0) {
      return []
    }
    return allCompanies1.map(company => ({
      label: company.name,
      options: company.locations?.map(loc => ({
        label: `${loc.location_title} (${company.name})`,
        value: loc.id,
        company_id: company.id,
      })) || [],
    }))
  }, [allCompanies1])

  const [selectedLocations, setSelectedLocations] = useState([])

  // Handle selection
  const handleLocationChange = selectedOptions => {
    const selectedCompanyIds = new Set() // To track selected company IDs

    // Prevent selecting multiple locations for the same company
    for (const option of selectedOptions) {
      if (selectedCompanyIds.has(option.company_id)) {
        Swal.fire({
          icon: "error",
          title: "Selection Error",
          text: "You can only select one location per company!",
        })
        return
      }
      selectedCompanyIds.add(option.company_id)
    }

    // Update state & Formik values
    setSelectedLocations(selectedOptions)
    const locationIds = selectedOptions.map(option => option.value)
    const companyIds = [...selectedCompanyIds]

    const externalCompaniesData = selectedOptions.map(option => ({
      company_id: option.company_id,
      companies_for_internal_external: 2, // Assuming fixed value
    }))

    const externalCompanyLocationsData = selectedOptions.map(option => ({
      company_id: option.company_id,
      location_for_internal_external: 2, // Assuming fixed value
      company_location_id: option.value,
    }))

    validation.setFieldValue("external_companies", externalCompaniesData)
    validation.setFieldValue("external_company_locations", externalCompanyLocationsData)
    validation.setFieldValue("location_id", locationIds)
    validation.setFieldValue("company_id", companyIds)
  }

  // Prefill selected options when `prefilledData` is provided
  useEffect(() => {
    if (prefilledData?.externalCompanyLocations && groupedLocationOptions.length > 0) {
      const preSelected = prefilledData.externalCompanyLocations
        .map(location =>
          groupedLocationOptions
            .flatMap(group => group.options)
            .find(option => Number(option.value) === Number(location.company_location_id))
        )
        .filter(Boolean) // Remove null values

      if (preSelected.length > 0) {
        setSelectedLocations(preSelected)
      }
    }
  }, [prefilledData, groupedLocationOptions])

  // Set Formik values from prefilled data
  useEffect(() => {
    if (prefilledData?.externalCompanyLocations) {
      const externalCompanyLocationsData = prefilledData.externalCompanyLocations.map(loc => ({
        company_id: loc.company_id,
        location_for_internal_external: 2,
        company_location_id: loc.company_location_id,
      }))

      const externalCompaniesData = prefilledData.externalCompanyLocations.map(loc => ({
        company_id: loc.company_id,
        companies_for_internal_external: 2,
      }))

      validation.setFieldValue("external_company_locations", externalCompanyLocationsData)
      validation.setFieldValue("external_companies", externalCompaniesData)
      validation.setFieldValue(
        "location_id",
        prefilledData.externalCompanyLocations.map(loc => loc.company_location_id)
      )
      validation.setFieldValue(
        "company_id",
        prefilledData.externalCompanyLocations.map(loc => loc.company_id)
      )
    }
  }, [prefilledData])

  return (
    <>
      <Col xxl={6} lg={6}>
        <div className="mb-3">
          <Label>Select V1malls Operator Company Locations</Label>
          <Select
            placeholder="Select Company Locations"
            options={groupedLocationOptions}
            value={selectedLocations}
            onChange={handleLocationChange}
            isMulti // Enables multiple selection
            closeMenuOnSelect={false} // Keeps dropdown open after selection
            className={
              validation.touched.location_id && validation.errors.location_id
                ? "is-invalid"
                : ""
            }
            styles={{
              groupHeading: (base) => ({
                ...base,
                color: "#000", // Black text color
                fontSize: "16px", // Increase font size
                fontWeight: "bold", // Make it bold
                padding: "10px 12px", // Add some padding
                backgroundColor: "#f8f9fa", // Light gray background (optional)
              }),
              option: (base, { data, isDisabled, isFocused, isSelected }) => ({
                ...base,
                paddingLeft: "16px", // Indent options under group labels
              }),
              multiValue: (base) => ({
                ...base,
                backgroundColor: "#bfbdbd", // Dark background color for selected values
                color: "#fff", // White text color
              }),
              multiValueLabel: (base) => ({
                ...base,
                color: "#343a40", // White text color for selected value label
              }),
              multiValueRemove: (base) => ({
                ...base,
                color: "#fff", // White color for remove icon (X)
                ':hover': {
                  backgroundColor: '#343a40', // Darken background on hover
                  color: '#fff', // Keep text color white on hover
                },
              }),
            }}
          />
          {validation.touched.location_id && validation.errors.location_id && (
            <FormFeedback type="invalid">
              {validation.errors.location_id}
            </FormFeedback>
          )}
        </div>
      </Col>
    </>
  )
}

export default CompanyLocationFormForOperator
