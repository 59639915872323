import formatNumberWithCommas from "components/Common/CommaSeparator"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { CardTitle, Col, Label, Modal, Row, Table } from "reactstrap"
import { createSelector } from "reselect"
import {
  billAmountList,
  getLatestProjectUnit,
  getReceiptPreview,
} from "store/actions"
import { ToWords } from "to-words"

const ReceiptPreviewModal = ({ isOpen, toggle, filterResponse, Id }) => {
  const toWords = new ToWords()

  const dispatch = useDispatch()
  const receiptPreviewState = state =>
    state.billing.receiptPreview?.receipt_details
  const receiptPreview = useSelector(receiptPreviewState)

  useEffect(() => {
    if (Id) {
      dispatch(getReceiptPreview(Id))
    }
  }, [Id])

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
    >
      <Row className="justify-content-center  mt-3">
        <h4 className="text-center text-dark">
          {receiptPreview && receiptPreview?.company_name}
        </h4>
        <h6 className="text-center ">
          {receiptPreview && receiptPreview?.location_title}
        </h6>
        <h6 className="text-center ">
          <b> CIN : </b> {receiptPreview && receiptPreview?.cin_no} ||{" "}
          <b> PAN : </b> {receiptPreview && receiptPreview?.pan_no}
        </h6>

        <h6 className="text-center ">
          <b> Email :</b> {receiptPreview && receiptPreview?.account_email} ||{" "}
          <b> Phone no : </b> {receiptPreview && receiptPreview?.contact_phone}
        </h6>
      </Row>

      <div className="modal-header">
        <button
          onClick={toggle}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <h4 className="text-center mt-4 text-dark">Receipt </h4>

      <div className="modal-body">
        <div className="table-responsive">
          <Table className="table mb-0">
            <tbody>
              <tr>
                <td style={{ width: "50%", borderBottomWidth: 0 }}>
                  <strong>Customer (Code)</strong>
                </td>
                <td
                  style={{
                    align: "right",
                    width: "50%",
                    valign: "top",
                    borderBottomWidth: 0,
                  }}
                  rowSpan="2"
                >
                  <table>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            borderBottomWidth: 0,
                            paddingRight: "15px",
                            paddingBottom: "15px",
                          }}
                        >
                          <strong>Receipt Date</strong>
                        </td>
                        <td
                          style={{
                            borderBottomWidth: 0,
                            paddingBottom: "15px",
                          }}
                        >
                          {" "}
                          :{" "}
                        </td>
                        <td
                          style={{
                            borderBottomWidth: 0,
                            paddingLeft: "15px",
                            paddingBottom: "15px",
                          }}
                        >
                          {" "}
                          {receiptPreview && receiptPreview.receipt_date}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            borderBottomWidth: 0,
                            paddingRight: "15px",
                            paddingBottom: "15px",
                          }}
                        >
                          <strong>Receipt No</strong>
                        </td>
                        <td
                          style={{
                            borderBottomWidth: 0,
                            paddingBottom: "15px",
                          }}
                        >
                          {" "}
                          :{" "}
                        </td>
                        <td
                          style={{
                            borderBottomWidth: 0,
                            paddingLeft: "15px",
                            paddingBottom: "15px",
                          }}
                        >
                          {receiptPreview && receiptPreview.receipt_no}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td style={{ borderBottomWidth: 0 }}>
                  {receiptPreview && receiptPreview.customer_name} (
                  {receiptPreview && receiptPreview.customer_code}
                  )<br />
                  <br />
                  {receiptPreview && receiptPreview.customer_address}{" "}
                  {receiptPreview && receiptPreview.city_name}{" "}
                  {receiptPreview && receiptPreview.state_name}{" "}
                  {receiptPreview && receiptPreview.pincode}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      <div className="modal-body">
        <div className="table-responsive">
          <Table className="table table-striped mb-0">
            <thead>
              <tr>
                <th>#</th>
                <th>Description</th>
                <th>Payment Mode</th>
                <th>Ins no.</th>
                <th style={{ textAlign: "end" }}>Amount</th>
              </tr>
            </thead>
            <tbody>
              {/* {((receiptPreview && receiptPreview.components) || []).map(
                  (row, index) => ( */}
              <tr>
                <th scope="row"> 1</th>
                <td>{receiptPreview && receiptPreview.description}</td>
                <td>{receiptPreview && receiptPreview.payment_type}</td>
                <td>{receiptPreview && receiptPreview.ins_no}</td>
                <td style={{ textAlign: "end" }}>
                  {receiptPreview &&
                    formatNumberWithCommas(receiptPreview.amount)}
                </td>
              </tr>
              {receiptPreview?.credit_or_debit === "credit" && (
                <tr>
                  <th scope="row"> 2</th>
                  <td>Credit Adjusted</td>
                  <td></td>
                  <td></td>
                  <td style={{ textAlign: "end" }}>
                    {receiptPreview &&
                      formatNumberWithCommas(
                        receiptPreview.credit_debit_amount
                      )}
                  </td>
                </tr>
              )}
              {receiptPreview?.credit_or_debit === "credit" && (
                <tr>
                  <th scope="row"> </th>
                  <td></td>
                  <td></td>
                  <td><strong>Total</strong></td>
                  <td style={{ textAlign: "end" }}>
                   
                      {receiptPreview &&
                        formatNumberWithCommas(receiptPreview.total_amount)}
               
                  </td>
                </tr>
              )}

              <tr>
                <td></td>
                <td>
                  <Table style={{ border: "1px solid #EEE " }}>
                    <tr>
                      <th style={{ padding: "15px" }}>INV DATE </th>
                      <th style={{ padding: "15px" }}>INV NO </th>
                      <th style={{ padding: "15px" }}>INV AMT </th>
                      <th style={{ padding: "15px" }}>RECEIPT</th>
                      <th style={{ padding: "15px" }}>TDS</th>
                    </tr>
                    {receiptPreview &&
                      receiptPreview.components.map((invoice, index) => (
                        <tr key={index}>
                          <td style={{ padding: "10px" }}>
                            {invoice.invoice_date === null ? receiptPreview.receipt_date : invoice.invoice_date}
                          </td>
                          <td style={{ padding: "10px" }}>
                            {invoice.invoice_no === null ? "Debit adjusted" : invoice.invoice_no}
                          </td>
                          <td style={{ textAlign: "end", padding: "10px" }}>
                            {invoice.invoice_amount=== null ? formatNumberWithCommas(invoice.amount) : formatNumberWithCommas(invoice.invoice_amount)}
                          </td>
                          <td style={{ textAlign: "end", padding: "10px" }}>
                            {formatNumberWithCommas(invoice.amount)}
                          </td>
                          <td style={{ textAlign: "end", padding: "10px" }}>
                            {formatNumberWithCommas(invoice.amount_withheld)}
                          </td>
                        </tr>
                      ))}
                  </Table>
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td colSpan={4} className="text-end">
                  <strong> Adjusted </strong>
                </td>
                <td style={{ textAlign: "end" }}>
                  {receiptPreview &&
                    formatNumberWithCommas(receiptPreview.utilised_amount)}
                </td>
              </tr>
              <tr>
                <td colSpan={4} className="text-end">
                  <strong> Remaining Credit </strong>
                </td>
                <td style={{ textAlign: "end" }}>
                  {receiptPreview &&
                    formatNumberWithCommas(receiptPreview.unsettled_amount)}
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="ms-2 mt-2 mb-2">
            <span>
              <strong> Amount in Words : </strong>
              Rupees{" "}
              {receiptPreview &&
                toWords?.convert(receiptPreview?.total_amount, {
                  // currency: true,
                  ignoreZeroCurrency: true,
                })}{" "}
              Only
            </span>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ReceiptPreviewModal
